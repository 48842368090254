
export const preventThrow = (fn) => {
  try {
    fn();
  } catch (e) {}
};

export const repeat = async (func, ms) => {
  while (true) {
    func();
    await new Promise((res) => setTimeout(res, ms));
  }
};
